import React from 'react';
import {Redirect} from 'react-router-dom';
import './LoginPage.css';


export default class LoginPage extends React.Component {
    constructor (props) {
        super(props);
        this.Auth = this.props.Auth;
        this.updateLoggedIn = this.props.updateLoggedIn;

        this.state = {
            isLoggedIn: false,
            username: '',
            password: '',
            confirmPass: '',
            email: '',
            isRegistering: false,
            isLoggingIn: false,

            error: '',
            errorMsgAr: [],
        }

    }
    
    async componentDidMount() {
        let isLoggedIn = await this.Auth.isLoggedIn();
        console.log('componentDidMount: Is logged in: ' + isLoggedIn)
        this.setState({isLoggedIn: isLoggedIn});
    }

    validateForm() {
        this.clearError()
        const { username, password, confirmPass, email, isRegistering } = this.state
        
        let errorFlag = false;
		if (isRegistering) {
            if (password !== confirmPass) {
                this.setError('The passwords did not match.')
                errorFlag = true;
            }
                    
            if (email.length <= 0) {
                errorFlag = true;
                this.setError('Email must not be empty.')
            }
        }
        
        if (username.length <= 0) {
            errorFlag = true;
            this.setError('Username must not be empty.')
            
        }
        
        if (password.length <= 0) {
            errorFlag = true;
            this.setError('Password must not be empty.')
        }


        if (errorFlag) {
            this.setState({
                password: '',
                confirmPass: '',
            });
            return false;
        }
        
        return true;
    }

    toggleLoginForm = (e) => {
        console.log('test')
        this.setState( (prevState) => ({isLoggingIn: !prevState.isLoggingIn}))
    }

    handleUsernameChange = (e) => {
		this.setState({username: e.target.value});
	}

	handlePasswordChange = (e) => {
		this.setState({password: e.target.value});
	}

	handleConfPassChange = (e) => {
		this.setState({confirmPass: e.target.value});
    }

    handleEmailChange = (e) => {
        this.setState({email: e.target.value});

    }
    

    handleSumbit = (e) => {
        e.preventDefault();
        if (!this.validateForm())
            return;

        this.clearError();
        const {username, password, email, isRegistering} = this.state

        if (isRegistering)
        {
            this.Auth.register(username, password, email)
            .then( (status) => {
                if(status >= 400)
                {
                    console.log('An error occured while trying to register.');
                }

                if( status === 200)
                    this.setState({isLoggedIn: true});
            })
        }
        else
        {
            this.Auth.login(username, password)
                .then( (status ) => {
                    console.log(status)
                    if(status === 401)
                    {
                        this.setError('Incorrect username or password.')
                        console.log('Wrong credentials');
                    }
                    else if(status >= 400)
                    {
                        this.setError('An error occured while trying to login.')
                        console.log('An error occured while trying to login.');
                    }
                    
                    if( status === 200) {
                        console.log('Succesfully logged in')
                        this.setState({isLoggedIn: true});
                    }

                })

        }
       
    }

    setError = (errorMsg) => {
        this.setState( (prevState) => ({
            error: true, 
            errorMsgAr: [...prevState.errorMsgAr, errorMsg]
        }));
    }

    clearError = () => {
        this.setState({error: false, errorMsgAr: []})
    }

    render() {
        let isLoggedIn = this.state.isLoggedIn;
        if (isLoggedIn)
        { 
            let url = "/panel/jobs";

            if(typeof this.props.location.state !== 'undefined')
                url = this.props.location.state.url;

            return <Redirect to={{pathname: `${url}`}}/>
        }
        const { username, password, confirmPass, email, isRegistering, isLoggingIn} = this.state;
        const { error, errorMsgAr } = this.state

        let loginFormClass = isLoggingIn ? "login-cont login-cont-expanded" : "login-cont";
        let hiddenStyle = isRegistering ? "expanded" : "hidden";

        return (
            <div className="Login-page">
                <div className="leftside">
                    <section id="Logo-cont"> 
                        <h1 id="Logo">Ad.Pool</h1>
                    </section>
                </div>
                <div className="rightside">
                    <div id="Slogan">
                        <span>Find, </span><span className="accent-color">Save</span> <span>and Track the best Ads</span>
                    </div>
                    <div id="Description" >
                        <p>We gather the best advertisments, while 
                            letting you save and track the ones that 
                            are most suited for you
                        </p>
                        <p>
                            Saving you time and money.
                        </p>
                    </div>

                    <button className="Login-btn" onMouseUp={this.toggleLoginForm}><span>Login</span> <img src="/images/forward-48.png" height="24px"></img></button>
                </div>
                <div className={loginFormClass}>
                    <section id="LoginForm" className="centered-col p-2">
                        <h2 className="centered-text">{isRegistering ? "Sign up" : "Sign in"}</h2>
                        <form 
                            className={"flex-col w-80 j-center expandable"}
                            onSubmit={this.handleSumbit}>
                            
                            {/* --- Username --- */}
                            <label label-for="username">Username:</label>
                            <input 
                                type="text" 
                                id="username" 
                                name="username" 
                                value={username} 
                                onChange={this.handleUsernameChange}/>
                            
                            {/* --- Password --- */}
                            <label label-for="password">Password:</label>
                            <input 
                                type="password" 
                                id="password" 
                                name="password" 
                                value={password} 
                                onChange={this.handlePasswordChange}/>

                            {/* --- Confirm Password --- */}
                            <label label-for="password-conf" className={hiddenStyle}>Confirm Password:</label>
                            <input 
                                type="password" 
                                className={hiddenStyle} 
                                id="password-conf" 
                                name="password-conf" 
                                value={confirmPass} 
                                onChange={this.handleConfPassChange} 
                                disabled={!isRegistering}/>

                            {/* --- E-Mail --- */}
                            <label label-for="email" className={hiddenStyle}>Email:</label>
                            <input 
                                type="text" 
                                className={hiddenStyle} 
                                id="email" 
                                name="email" 
                                value={email} 
                                onChange={this.handleEmailChange} 
                                disabled={!isRegistering}/>

                            {/* --- Error Message */}
                            { error && 
                                errorMsgAr.map( (errorMsg) => {
                                    return (<li className="error-msg fade-color">{errorMsg}</li>)
                                })
                            }
                            
                            {/* --- Submit Btn --- */}
                            <input type="submit" value={isRegistering ? "Create Account" : "Log in"}></input>
                        </form>
                        <span className="join-msg" 
                            onClick={() => this.setState(prevState => ({isRegistering: !prevState.isRegistering}))}>
                                Want to Join us instead?
                        </span>                        
                        {/* <span className={"invite-only-msg " + hiddenStyle}>Currently the service is Invite-Only!</span> */}
                    </section>
                    <img 
                        className="close-login-form-btn" 
                        src="/images/multiply-24.png" 
                        alt="Close login form button"
                        onClick={this.toggleLoginForm}></img>
                </div>
            </div>
        );
    }
}
