import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import './Panel.css';
import TopBanner from './TopBanner';


export default class Panel extends React.Component {
    constructor(props) {
        super(props);
        this.Auth = this.props.Auth;

        this.updateLoggedIn = this.props.updateLoggedIn;

        this.getData = this.props.getData;
        this.getNextPage = this.props.getNextPage;
        this.getSavedAds = this.props.getSavedAds;
        this.saveAd = this.props.saveAd;
        this.updateSavedAd = this.props.updateSavedAd;
        this.deleteAd = this.props.deleteAd;

        this.state = {
            curUser: this.Auth.getUser(),
            dataArray: [],
            savedArray: [],
            hasLoaded: false,
            currentPage: 0,
            pageSize: null,

            isLoggedIn: false,
            shouldScroll: false,

            settings: {
                showOnlyActive: false,
                compactView: false,
                autoLoadMore: false,
            }
        }
    }

    async componentDidMount() {
        let isLoggedIn = await this.Auth.isLoggedIn();
        if (isLoggedIn) {
            this.setState({ isLoggedIn: isLoggedIn, hasCheckedLogin: true })

            const [firstResponse, secondResponse] = await Promise.all([
                this.getData(),
                this.getSavedAds(this.Auth)
            ]);

            let dataArray = firstResponse.data.results
            this.setState({ dataArray: dataArray, currentPage: 1, pageSize: dataArray.length })

            var savedArray = secondResponse.data
            this.setState({ savedArray: savedArray, hasLoaded: true })
        }
        else {
            this.setState({ isLoggedIn: isLoggedIn, hasCheckedLogin: true })
        }
    }


    loadNextPage = () => {
        this.setState((prevState, props) => {
            return { currentPage: prevState.currentPage + 1 };
        });

        let currentPage = this.state.currentPage + 1;
        this.getNextPage(currentPage)
            .then((res) => {
                var adArrayNew = this.state.dataArray.concat(res.data.results)

                this.setState({ dataArray: adArrayNew })
            });
    }

    refreshSavedAds = () => {
        this.getSavedAds(this.Auth)
            .then((res) => {
                var savedArray = res.data
                this.setState({ savedArray: savedArray })
            })
    }

    renderNewAds = (dataArray) => {

        /*  Find FIRST new ad that got sent back 
            Also: Pages' Number start from 1
        */
        let { currentPage, pageSize } = this.state;
        let index;
        /*  We actually search for the one before the newest
            for better results when scrolling to it. (hence the "-1")
        */
        if (currentPage > 1) {
            index = (currentPage * pageSize - pageSize - 1)
        }

        /* -- Date required values -- */
        // Today
        let today = Date.now();
        // Current Ad's Date (will updated in every loop iter)
        let currDate = null;
        // Previous Ad's Date (will be updated in every loop iter)
        let prevDate = new Date(dataArray[0].posted_date)
        let diffTime, upDaysCurrentAd, upDaysPrevAd, dayDiff

        let shouldScroll = this.state.shouldScroll


        return dataArray.map((ad, i) => {

            // Calculate day difference 
            currDate = new Date(ad.posted_date)
            diffTime = Math.abs(currDate - today);
            upDaysCurrentAd = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

            diffTime = Math.abs(prevDate - today);
            upDaysPrevAd = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

            dayDiff = Math.abs(upDaysCurrentAd - upDaysPrevAd);

            // Update prevDate to current one for the next iteration
            prevDate = currDate;


            return (
                <>
                    {(dayDiff >= 1) &&
                        <div>
                            <hr />
                            <h4>{upDaysCurrentAd} Days ago</h4>
                        </div>
                    }
                    {(i === index)
                        ? <ScrollableAd
                            currentPage={currentPage}
                            ad={ad}
                            saveAd={this.saveAd}
                            refreshSavedAds={this.refreshSavedAds} />
                        : <Ad
                            key={ad.id}
                            ad={ad}
                            saveAd={this.saveAd}
                            refreshSavedAds={this.refreshSavedAds} />
                    }
                </>
            );
        })
    }

    renderSavedAds = (savedArray) => {

        if (savedArray.length === 0)
            return( <h4 className="no-saved-ads-header"> This is where your saved ads will show up </h4> );

        return savedArray.reverse().map((ad, i) => {
            return (<SavedAd
                key={ad.id}
                savedAd={ad}
                updateSavedAd={this.updateSavedAd}
                deleteAd={this.deleteAd}
                refreshSavedAds={this.refreshSavedAds} />
            );
        })
    }

    render() {
        let { isLoggedIn, hasCheckedLogin } = this.state;

        let { dataArray, savedArray, hasLoaded } = this.state;
        let currUrl = this.props.location.pathname;
        return (
            <>
                {(!isLoggedIn && hasCheckedLogin)
                    ? <Redirect to={{ pathname: `/`, state: { url: currUrl } }} />
                    : <div className="Panel">
                        <TopBanner Auth={this.Auth} />
                        <div className="PanelInner">
                            <div className="Left fl j-center">
                                {hasLoaded
                                    ?
                                    <>
                                        <h3>New Ads</h3>
                                        <hr />
                                    </>
                                    : <h4>Loading data...</h4>
                                }
                                {hasLoaded &&
                                    this.renderNewAds(dataArray)
                                }
                                {hasLoaded && <button className="Load-more-btn" onClick={this.loadNextPage}>Load More</button>}
                            </div>
                            <div className="Right fl">
                                {hasLoaded
                                    ? 
                                    <>
                                        <h3>Saved Ads</h3>
                                        <hr />

                                    </>
                                    : <h4>Loading data...</h4>
                                }
                                {hasLoaded
                                    && this.renderSavedAds(savedArray)
                                }
                            </div>
                        </div>
                    </div>
                }
            </>
        );
    }
}

function ScrollableAd(props) {

    let { ad, saveAd, refreshSavedAds, currentPage } = props
    // Scroll to view function
    const fieldRef = React.useRef(null);
    React.useEffect(() => {
        fieldRef.current.scrollIntoView({ behavior: "smooth" });
    },[currentPage]);
    return (
        <>
            <Ad
                key={ad.id}
                propRef={fieldRef}
                ad={ad}
                saveAd={saveAd}
                refreshSavedAds={refreshSavedAds} />
            <div className="Older-label-cont">
                <hr />
                <h5 className="Older-label">Older</h5>
                <hr />
            </div>
        </>
    );
}

class Ad extends React.Component {

    constructor(props) {
        super(props);

        this.saveAd = this.props.saveAd;
        this.refreshSavedAds = this.props.refreshSavedAds;

        this.ad = this.props.ad
        let ad = this.ad;

        let lang = "el-GR";

        let postedDate = new Date(ad.posted_date);
        let postedDateStr = "Posted on: " + postedDate.toLocaleString(lang, {month: "long"});
        let postedDateStr2 = GetDateString(postedDate);

        let upForDays, endDate;
        let status = ad.active ? "Active" : "Archived";

        if (status === "Archived")
            endDate = new Date(ad.deleted_date);
        else
            endDate = Date.now();

        let diffTime = Math.abs(endDate - postedDate);
        upForDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

        this.state = {
            status: status,
            postedDateStr: postedDateStr,
            postedDateStr2: postedDateStr2,
            IndicatorColor: ad.active ? "Green-ind" : "Red-ind",
            upForDaysStr: "Up for " + upForDays + (upForDays === 1 ? " day" : " days"),

            isStatusHovered: false,
            isAdHovered: false,
            isClicked: false,

            comment: '',
            adID: ad.id
        }
    }


    handleAdHover = () => {
        this.setState((prevState) => {
            return { isAdHovered: !prevState.isAdHovered };
        });
    }

    handleStatusHover = () => {
        this.setState((prevState) => {
            return { isStatusHovered: !prevState.isStatusHovered };
        });
    }

    updateComment = (comment) => {
        this.setState({ comment: comment })
    }

    handleAddButtonClick = () => {
        let { comment, adID } = this.state;
        this.saveAd(adID, comment);
        this.refreshSavedAds();
    }

    handleMouseClick = () => {
        this.setState((prevState) => {
            return { isClicked: !prevState.isClicked };
        });
    }

    render() {
        let propRef = this.props.propRef;

        let { IndicatorColor, isStatusHovered,
            isClicked, upForDaysStr, postedDateStr, postedDateStr2
        } = this.state;

        let ad = this.ad;

        let AdClassname = 'Ad' + (isClicked ? ' Ad-open' : '');
        let bottomClassName = 'Ad-bottom-section' + (isClicked ? ' Ad-bottom-section-visible' : '');
        let MoreBtnClassName = 'Ad-more-btn' + (isClicked ? ' Ad-more-btn-expanded' : '');
        return (
            <div ref={propRef}
                className={AdClassname}
                onMouseEnter={this.handleAdHover}
                onMouseLeave={this.handleAdHover}>

                {/* --- Top Section --- */}
                <div className="Ad-top-section flex-col">
                    <div className="flex-col">
                        <div className="Status-indicator-cont flex-row">
                            {/*  onMouseEnter={this.handleStatusHover} onMouseLeave={this.handleStatusHover} */}
                            <div className="Active-indicator-cont flex-row">
                                <div className={"Active-indicator " + IndicatorColor}>
                                    {isStatusHovered
                                        && <div className="Posted-date-popup">
                                            <div>{postedDateStr}</div>
                                        </div>
                                    }
                                </div>
                                <span className={"Up-days"}>{upForDaysStr + " - " + postedDateStr2}</span>

                            </div>
                        </div>
                        {/* <span className="vertical-divider"></span> */}
                        <p className="Ad-descr">{ad.description}</p>
                    </div>


                    {/* --- More Button --- */}
                    <div className={MoreBtnClassName} onClick={this.handleMouseClick}>
                        <span>
                            More 
                            <img src="/images/down-arrow.png"></img>
                        </span>
                    </div>
                </div>


                {/* --- Bottom Section --- */}
                <div className={bottomClassName}>
                    <p>{ad.info.replace('Πληρ. Τηλ.', 'Info:')}</p>
                    <div className="flex-row Ad-save-cont">
                        <div className="flex-col Ad-comment-input">
                            <CommentInput updateComment={this.updateComment} adID={ad.id} />
                        </div>
                        <button
                            className="Ad-save-btn"
                            onClick={this.handleAddButtonClick}>Save</button>
                    </div>
                </div>
            </div>
        );
    }
}

function GetDateString(date){
    let dateStrPostfix;
    switch(date.getDate())
    {
        case 1:
            dateStrPostfix = "st";
            break;
        case 2: 
            dateStrPostfix = "nd";
            break;
        case 3: 
            dateStrPostfix = "rd";
            break;
        default: 
            dateStrPostfix = "th";
    }
    return "Posted on " 
            + date.toLocaleString("default", {weekday: "long"}) 
            + ", " 
            + date.getDate() 
            + dateStrPostfix
            + " of " 
            + date.toLocaleString("default", {month: "long"})
}

class SavedAd extends React.Component {
    constructor(props) {
        super(props);

        let posting = this.props.savedAd.posting;
        this.updateSavedAd = this.props.updateSavedAd;
        this.deleteAd = this.props.deleteAd;
        this.refreshSavedAds = this.props.refreshSavedAds;

        let postedDate = new Date(posting.posted_date);
        let postedDateStr = postedDate.toLocaleDateString("el-GR");
        let postedDateStr2 = GetDateString(postedDate);

        let upForDays, endDate;
        let status = posting.active ? "Active" : "Archived";

        if (status === "Archived")
            endDate = new Date(posting.deleted_date);
        else
            endDate = Date.now();

        let diffTime = Math.abs(endDate - postedDate);
        upForDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

        this.state = {
            postedDateStr: postedDateStr,
            postedDateStr2: postedDateStr2,
            upForDays: upForDays,
            IndicatorColor: posting.active ? "Green-ind" : "Red-ind",
            upForDaysStr: "Up for " + upForDays + (upForDays === 1 ? " day" : " days"),


            savedAd: this.props.savedAd,
        }
    }

    updateComment = (comment) => {
        let savedAd = this.state.savedAd;
        savedAd.comment = comment;
        this.setState({ savedAd: savedAd });
    }

    handleUpdate = () => {
        let savedAd = this.state.savedAd;
        this.updateSavedAd(savedAd.id, savedAd.comment, savedAd.status);
    }

    handleDelete = () => {
        this.deleteAd(this.state.savedAd.id)
            .then(() => {
                this.refreshSavedAds();
            });
    }

    setStatus = (code) => {
        let savedAd = this.state.savedAd;
        savedAd.status = code;
        this.setState({
            savedAd: savedAd
        }, () => {
            this.handleUpdate();
        });
    }

    render() {
        let { savedAd, IndicatorColor, upForDaysStr, postedDateStr2 } = this.state;

        let status;
        let statCode = savedAd.status;
        let indicatorClass
        switch (statCode) {
            case 'cons':
                status = 'Considering'
                indicatorClass = 'Indicator-cons'
                break;
            case 'pend':
                status = 'Pending'
                indicatorClass = 'Indicator-pend'
                break;
            case 'rej':
                status = 'Rejected'
                indicatorClass = 'Indicator-rej'
                break;
            default:
                status = 'err'
        }


        let posting = savedAd.posting;
        return (
            <div className="Ad Saved-ad">
                <div className={"Side-indicator " + indicatorClass}>
                </div>
                <div className="Saved-ad-cont">
                    <div className="Status-indicator-cont flex-row" onMouseEnter={this.handleStatusHover} onMouseLeave={this.handleStatusHover}>
                        <div className="Active-indicator-cont flex-row">
                            <div className={"Active-indicator " + IndicatorColor}>
                            </div>
                            <span className="Up-days">{upForDaysStr + " - " + postedDateStr2}</span>
                        </div>
                        {/* <div className="Status">
                            <button className="Status-btn Btn-cons" disabled={statCode === 'cons'} onClick={() => this.setStatus('cons')}>Considering</button>
                            <button className="Status-btn Btn-pend" disabled={statCode === 'pend'} onClick={() => this.setStatus('pend')}>Pending</button>
                            <button className="Status-btn Btn-rej" disabled={statCode === 'rej'} onClick={() => this.setStatus('rej')}>Rejected</button>
                        </div> */}
                    </div>
                    <p className="Ad-descr">{posting.description}</p>
                    <p className="Saved-ad-info">{posting.info.replace('Πληρ. Τηλ.', 'Info:')}</p>

                    <div className="Saved-ad-comment-cont flex-col">
                        <label>Personal comment:</label>
                        <SavedCommentInput comment={savedAd.comment} updateComment={this.updateComment} adID={savedAd.id} />
                    </div>
                    <div className="Saved-ad-bottom-btns flex-row">
                        <div className="Status">
                            <button className="Status-btn Btn-cons" disabled={statCode === 'cons'} onClick={() => this.setStatus('cons')}>Considering</button>
                            <button className="Status-btn Btn-pend" disabled={statCode === 'pend'} onClick={() => this.setStatus('pend')}>Pending</button>
                            <button className="Status-btn Btn-rej" disabled={statCode === 'rej'} onClick={() => this.setStatus('rej')}>Rejected</button>
                        </div>
                        <div className="Saved-ad-action-btns-cont">
                            <button className="Action-btn Btn-save" onClick={this.handleUpdate}>Save</button>
                            <button className="Action-btn Btn-delete" onClick={this.handleDelete}>Delete</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

class CommentInput extends React.Component {

    constructor(props) {
        super(props)

        this.refreshSavedAds = this.props.refreshSavedAds;
        this.updateComment = this.props.updateComment;

        this.state = {
            comment: this.props.comment || '',
            adID: this.props.adID
        }
    }

    handleCommentChange = (e) => {
        this.setState({ comment: e.target.value })
        this.updateComment(e.target.value)
    }


    render() {
        let { comment } = this.state;
        return (
            <input
                className="Ad-comment-input-box"
                placeholder="Add Optional Comment..."
                value={comment}
                onChange={this.handleCommentChange}></input>
        );
    }
}

class SavedCommentInput extends React.Component {

    constructor(props) {
        super(props)

        this.refreshSavedAds = this.props.refreshSavedAds;
        this.updateComment = this.props.updateComment;

        this.state = {
            comment: this.props.comment || '',
            adID: this.props.adID,
            initHeight: "10px",
        }
    }

    handleCommentChange = (e) => {
        this.setState({ comment: e.target.value })
        this.updateComment(e.target.value)
    }

    setHeight = (e) => {
        this.setState({ initHeight: e.target.style.height })
        e.target.style.height = "0px";
        e.target.style.height = (e.target.scrollHeight) + "px";
    }


    render() {
        let { comment } = this.state;
        return (
            <textarea
                className="Ad-comment-input-box"
                placeholder="Add Optional Comment..."
                value={comment}
                onFocus={this.setHeight}
                onChange={this.handleCommentChange}></textarea>
        );
    }
}