import axios from 'axios';

export default class Api {

    constructor ( {Auth} ) {
        this.Auth = Auth;
    }

    getJobs = () => {
        let headers = this.getHeaders();
        
        return axios.get(`/api/v1/adstore/jobs/`,
            { headers: { ...headers } })
            .then( (res) => {
                return Promise.resolve(res);
            });
            
    }
    

    getJobsNextPage = (nextPageId) => {

        let headers = this.getHeaders();
    
        return axios.get(`/api/v1/adstore/jobs/?page=${nextPageId}`,
            { headers: { ...headers } })
            .then(  (res) => {
                return Promise.resolve(res);
            });
    }

    getApartments = () => {
        let headers = this.getHeaders();
        
        return axios.get(`/api/v1/adstore/apartments/`,
            { headers: { ...headers } })
            .then( (res) => {
                this.Auth.refreshToken();
                return Promise.resolve(res);
            });
            
    }
    

    getApartmentsNextPage = (nextPageId) => {

        let headers = this.getHeaders();
    
        return axios.get(`/api/v1/adstore/apartments/?page=${nextPageId}`,
            { headers: { ...headers } })
            .then( (res) => {
                return Promise.resolve(res);
            });
    }

    // === SAVED JOBS  ===

    getSavedJobs = () => {
        let headers = this.getHeaders();
    
        let user_id = this.Auth.getUserID();
        return axios.get(`/api/v1/userstore/users/${user_id}/saved/jobs/`,
            { headers: { ...headers } })
            .then( (res) => {
                return Promise.resolve(res);
            });
    }

    saveJobAd = (posting_id, comment, status) => {
        let headers = this.getHeaders();
        let user_id = this.Auth.getUserID();
        let data = {
            "posting": posting_id,
            "comment": comment,
            "status": status,
            "owner": user_id
        }
    
        return axios.post(`/api/v1/userstore/users/${user_id}/saved/jobs/`,
            data,
            { headers: {...headers} })
            .then( (res) => {
                return Promise.resolve(res);
            });
    }

    updateJobAd = (savedAdID, comment, status) => {
        let headers = this.getHeaders();
        let user_id = this.Auth.getUserID();

        let data = {
            "comment": comment,
            "status": status,
        }
    
        return axios.patch(`/api/v1/userstore/users/${user_id}/saved/jobs/${savedAdID}/`,
            data,
            { headers: {...headers} })
            .then( (res) => {
                return Promise.resolve(res);
            });
    }

    deleteJobAd = (savedAdID) => {

        let headers = this.getHeaders();
        let user_id = this.Auth.getUserID();

        return axios.delete(`/api/v1/userstore/users/${user_id}/saved/jobs/${savedAdID}/`,
            { headers: { ...headers } })
            .then( (res) => {
                return Promise.resolve(res);
            });
    }


    // === SAVED APARTMENTS  ===

    getSavedApartments = () => {
        let headers = this.getHeaders();
    
        let user_id = this.Auth.getUserID();
        return axios.get(`/api/v1/userstore/users/${user_id}/saved/apartments/`,
            { headers: { ...headers } })
            .then( (res) => {
                return Promise.resolve(res);
            });
    }

    saveApartmentAd = (posting_id, comment, status) => {
        let headers = this.getHeaders();
        let user_id = this.Auth.getUserID();
        let data = {
            "posting": posting_id,
            "comment": comment,
            "status": status,
            "owner": user_id
        }
    
        return axios.post(`/api/v1/userstore/users/${user_id}/saved/apartments/`,
            data,
            { headers: { ...headers } })
            .then( (res) => {
                return Promise.resolve(res);
            });
    }

    updateApartmentsAd = (savedAdID, comment, status) => {
        let headers = this.getHeaders();
        let user_id = this.Auth.getUserID();

        let data = {
            "comment": comment,
            "status": status,
        }
    
        return axios.patch(`/api/v1/userstore/users/${user_id}/saved/apartments/${savedAdID}/`,
            data,
            { headers: {...headers} })
            .then( (res) => {
                return Promise.resolve(res);
            });
    }

    deleteApartmentAd = (savedAdID) => {
        let headers = this.getHeaders();
        let user_id = this.Auth.getUserID();

        return axios.delete(`/api/v1/userstore/users/${user_id}/saved/apartments/${savedAdID}/`,
            { headers: { ...headers } })
            .then( (res) => {
                return Promise.resolve(res);
            });
    }

    getHeaders = () => {

        // Set up headers for all requests
        let AuthStr = 'Bearer ' + this.Auth.getAccessToken()
        return {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthStr
        };
    }
}