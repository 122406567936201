import decode from 'jwt-decode';
import axios from 'axios';

export default class Auth {

    // Declare JWT
    jwtToken;

    login = (username, password) => {
        const headers = this.getHeaders();

        const data = {
            username: username,
            password: password
        }

        this.setUser(username)

        return axios.post(`/api/v1/token/`,
            data,
            { headers: { ...headers } })
            .then( (res) => {
                if (res.status === 200)
                {
                    // Save token using localstorage
                    this.setAccessToken(res.data.access)
                    this.setRefreshToken(res.data.refresh);
                    this.setUser(username);
                    let jwt = decode(res.data.access);
                    this.setUserID(jwt.user_id);
                }
                return Promise.resolve(res.status);
            })
            .catch( (error) => {
                return Promise.resolve(error.response.status);
            });
    }

    register = (username, password, email) => {
        const headers = this.getHeaders();

        const data = {
            email: email,
            username: username,
            password: password
        }

        this.setUser(username)

        return axios.post(`/api/v1/userstore/users/`,
            data,
            { headers: {...headers}})
            .then( (res) => {
                
                if (res.status === 201)
                {
                    return this.login(username, password)
                }

                return Promise.resolve(res.status);
            })
    }

    refreshToken = () => {
        const headers = this.getHeaders();

        if (!this.getRefreshToken())
        {
            console.log('No refresh token found');
            return Promise.resolve(false);
        }

        const data = {
            refresh: this.getRefreshToken()
        }

        console.log('Refreshing token')

        return axios.post(`/api/v1/refresh/`,
            { headers: { ...headers } },
            { data: { ...data } })
            .then(res => {
                
                if (res.status === 200)
                {
                    // Save the new Access token
                    this.setAccessToken(res.data.access)
                    return Promise.resolve(true);
                }
                else
                {
                    this.logout();
                    return Promise.resolve(false);
                }

            })
            .catch( () => {
                console.log('Error while trying to refresh token');
                this.logout();
                return Promise.resolve(false);
            })
    }

    isLoggedIn = () => {

        const token = this.getAccessToken();
        // if token is not empty AND is NOT expired, return TRUE
        const isValidToken = !!token && !this.isTokenExpired(token);
		if(!isValidToken){

            // JWT was not valid, try to refresh it
                return this.refreshToken()
                    .then( (success) => {
                        if ( success ) {
                            console.log('--- Refreshed token')
                            return Promise.resolve(true);
                        }
                        else {
                            console.log('-- Couldn\'t refresh token')
                            return Promise.resolve(false);
                        }
                    });

            // Couldn't refresh access token 
            // or refresh token didn't exist
            // return false
        }
        else
        {
            return true
        }
    }

    isTokenExpired = (token) => {
        if (process.env.NODE_ENV === 'development')
            console.log('Checking if token is expired');

        // Decode token
        token = decode(token);
        let expDate = new Date(0);
        expDate.setSeconds(token.exp);

        // Compare to current date
        let currentDate = new Date();
        if ( expDate <= currentDate)
        {
            if (process.env.NODE_ENV === 'development')
                console.log('Expired token!');
            return true;
        }
        else
        {
            return false;
        }
        
    }

    setAccessToken = (jwtToken) => {
        this.jwtToken = jwtToken;
    }

    getAccessToken = () => {
        return this.jwtToken;
    }


    setRefreshToken = (userRToken) => {
        localStorage.setItem('userRefreshToken', userRToken);
    }

    getRefreshToken = () => {
        return localStorage.getItem('userRefreshToken');
    }

    setUser = (username) => {
        localStorage.setItem('username', username);
    };

    getUser = () => {
        return localStorage.getItem('username');
    }

    setUserID = (user_id) => {
        localStorage.setItem('user_id', user_id);
    };

    getUserID = () => {
        return localStorage.getItem('user_id');
    }


    logout = () => {
        //** Clear user token and profile data from localStorage
        this.jwtToken = null;
        localStorage.removeItem('username');
        localStorage.removeItem('user_id');
        localStorage.removeItem('userRefreshToken');
    }

    getHeaders() {
        // Set up headers for all requests
        return {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        };
    }
}