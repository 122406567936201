import React from 'react';
import {Redirect} from 'react-router-dom';
import './Panel.css';
import { withRouter } from "react-router";

export default class TopBanner extends React.Component
{
    constructor(props)
    {
        super(props);
        this.Auth = this.props.Auth;
        this.state = 
        {
            redirect: false,
            redirectUrl: '/'
        }
    }


    render()
    {
        return(
            <div className="Top-banner flex-row a-center">
                <div className="flex-row a-center">
                    <span id="Logo-banner">Ad.Pool</span>
                    <LinkList />
                </div>
                <Link id="Logout-btn" text="Logout" url="/" Auth={this.Auth}/>
            </div> 
        )
    }
}

class LinkList extends React.Component{
    render() {
        return( 
            <div className="Linked-list">
                <Link text="Jobs" url="/panel/jobs"/>
                <Link text="Apartments"  url="/panel/apartments"/>
            </div>
        );
    }
}

class LinkWithoutRouter extends React.Component {

    constructor(props)
    {
        super(props);
        this.Auth = this.props.Auth;

        this.state = {
            redirect: false
        };
    }

    handleOnClick = (e) => {
        e.preventDefault();

        if (e.target.id === 'Logout-btn')
            this.Auth.logout();

        this.setState({redirect: true});
    }
    render() {
        let {text, url, id} = this.props;
        let currentUrl = this.props.location.pathname

        if (this.state.redirect) {
            if(currentUrl !== url)
                return (<Redirect to={url}/>);
        }

        return( <button id={id} className="Link" onClick={this.handleOnClick}>{text}</button> );
    }
}
const Link = withRouter(LinkWithoutRouter)