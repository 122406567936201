import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route } from 'react-router-dom';

import './index.css';
import * as serviceWorker from './serviceWorker';

import Auth from './AuxFiles/Auth'
import Api from './AuxFiles/Api';

import LoginPage from './LoginPage';
import Panel from './Panel/Panel'

class App extends React.Component {

    constructor(props) {
        super(props);
        this.Auth = new Auth();
        this.Api = new Api({Auth: this.Auth});
    }

    updateLoggedIn() {
        const status = this.Auth.isLoggedIn()
        return status;
    }


    render() {
        return(
            <BrowserRouter>
                <Route
                    exact path={['/', '/login']}
                    render={ (props) =>
                        <LoginPage
                            {...props} 
                            Auth={this.Auth} />
                    }/>
                <Route
                    path={['/panel/apartments']}
                    render={ (props) =>
                        <Panel 
                            {...props} 
                            Auth={this.Auth}
                            getData={this.Api.getApartments}
                            getNextPage={this.Api.getApartmentsNextPage}
                            saveAd={this.Api.saveApartmentAd}
                            getSavedAds={this.Api.getSavedApartments}
                            updateSavedAd={this.Api.updateApartmentsAd}
                            deleteAd={this.Api.deleteApartmentAd}
                            updateLoggedIn={this.updateLoggedIn}/>
                    }/>
                <Route
                    path={['/panel/jobs']}
                    render={ (props) =>
                        <Panel 
                            {...props} 
                            Auth={this.Auth}
                            getData={this.Api.getJobs}
                            getNextPage={this.Api.getJobsNextPage}
                            saveAd={this.Api.saveJobAd}
                            getSavedAds={this.Api.getSavedJobs}
                            updateSavedAd={this.Api.updateJobAd}
                            deleteAd={this.Api.deleteJobAd}
                            updateLoggedIn={this.updateLoggedIn}/>
                    }/>
            </BrowserRouter>
        )
    }
}


ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
